
.App {
  display: flex;
  flex-direction: column;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

nav a {
  color: black;
  text-decoration: none;
  margin: 0 10px;
}

.banner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}