.footer {
  position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: large;
    color: black;
    font-weight: bold;
    margin-bottom: 10px;
  }